import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import LoginView from "../views/LoginView.vue";
import AdminView from "../views/AdminView.vue";
import storage from "../classes/store";
import Api from "../classes/api";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, _, next) => {
  try {
    if (to.query.key) {
      storage.state.appkey = to.query.key as string;
    }
  } catch (_) {
    // nothing
  }

  const api = new Api();

  let user = storage.state.currentUser;
  if (!user) {
    try {
      const u = await api.user();
      storage.setUser(u);
      user = u.email;
    } catch (e) {
      user = "";
    }
  }

  // for /login and /about, no need to be loggedIn
  if (to.name == "login" || to.name == "about") {
    next();
  } else if (!user) {
    next({ name: "login" });
  } else if (to.name == "admin" && !storage.state.isAdmin) {
    next({ name: "home" });
  } else {
    next();
  }
});

export default router;
