import User from "./user";
export default {
  state: {
    currentUser: "",
    isAdmin: false,
    username: "",
    enigmaCount: 0,
    appkey: "",
    state: false,
    loaded: false,
  },
  setUser(user: User) {
    this.state.currentUser = user.email;
    this.state.username = user.username;
    this.state.isAdmin = user.is_admin;
  },
  loggedIn(): boolean {
    return this.state.currentUser != "";
  },
  get loaded() {
    return this.state.loaded;
  },
  set loaded(value: boolean) {
    this.state.loaded = value;
  },
};
