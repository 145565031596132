import User from "@/classes/user";
import storage from "@/classes/store";
import Enigma from "@/classes/enigma";

class Users {
  items!: Array<User>;
}

export default class Api {
  baseUrl = process.env.VUE_APP_API;

  async login(user: string, password: string): Promise<User> {
    return fetch(`${this.baseUrl}/api/v1/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: user,
        password: password,
      }),
    }).then((response) => response.json());
  }

  async logout(): Promise<void> {
    return fetch(`${this.baseUrl}/api/v1/user/logout`).then(() => {
      storage.setUser(new User());
    });
  }

  async register(user: User): Promise<User> {
    return fetch(`${this.baseUrl}/api/v1/user/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    }).then((response) => response.json());
  }

  async user(): Promise<User> {
    return fetch(`${this.baseUrl}/api/v1/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  }

  async enigma(id: number): Promise<Enigma> {
    return fetch(`${this.baseUrl}/api/v1/enigma/${id}`)
      .then((response) => response.json())
      .then((response) => Object.assign(new Enigma(), response));
  }

  async submitAnswer(answer: string): Promise<Response> {
    return fetch(`${this.baseUrl}/api/v1/enigma/answer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        answer: answer,
      }),
    });
  }

  async countEnigmas(): Promise<number> {
    return fetch(`${this.baseUrl}/api/v1/enigmas/count`)
      .then((response) => response.json())
      .then((response) => response.count);
  }

  async allUsers(): Promise<User[]> {
    return fetch(`${this.baseUrl}/api/v1/all`)
      .then((response) => response.json())
      .then((response: Users) =>
        response.items.map((user) => Object.assign(new User(), user))
      );
  }

  async state(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      fetch(`${this.baseUrl}/api/v1/state`).then(
        (resp) => {
          resolve(resp.status <= 201);
        },
        () => {
          resolve(false);
        }
      );
    });
  }

  async switchState(state: boolean): Promise<boolean> {
    const call = state ? "start" : "stop";
    return fetch(`${this.baseUrl}/api/v1/${call}`).then(() => {
      return state;
    });
  }

  listenUsers(): EventSource {
    return new EventSource(`${this.baseUrl}/api/v1/all/sse`);
  }
}
